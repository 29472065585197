@import url("https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@300;400&display=swap");

* {
  box-sizing: border-box;
}

html,
body {
  min-height: 100vh;
  margin: 0;
  overflow: hidden;
}

.webgl,
.overlay {
  position: fixed;
  top: 0;
  left: 0;
}

.overlay {
  /* background: rgba(255, 255, 255, 0.1); */
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-width: 100vw;
  min-height: 100vh;
  font-family: "Roboto Mono", monospace;
  font-size: 20px;
  color: #ffffff;
  pointer-events: none;
}

.title {
  text-align: center;
}
